<template>
  <div class="card">

    <div class="card-header header-elements-inline" style="background-color: whitesmoke; padding: 10px;">
      <h5 class="card-title">Vch No: {{voucher.doc_no}} Voucher Preview</h5>
      <div class="header-elements">
        <div class="list-icons">
          <a class="list-icons-item" data-action="remove" @click="closeThis"></a>
        </div>
      </div>
    </div>



    <div class="card-body">

      <div class="row">

        <div class="col-md-3">

        </div>
        <div class="col-md-7">

        </div>

        <div class="col-md-2">
          <div class="form-group form-group-material">
            <label class="control-label text-semibold">Date</label>
            <input id="txtDate" type="date" class="form-control" v-if="voucher" v-model="voucher.doc_date">
          </div>
        </div>
      </div>


      <div class="row">
        <!--  Detail Table -->
        <div class="table-responsive">

          <table id="mytable" class="table table-no-bordered">
            <thead style="background-color: lightgrey">
            <tr>
              <th style="width:50px;">S.No</th>
              <th style="text-align: left;">Cr/Dr</th>
              <th >Particulars</th>
              <th >Description</th>
              <th style="width:150px; text-align: right;">Credit</th>
              <th style="width:150px; text-align: right;">Debit</th>
              <th style="width: 30px;">Action</th>
            </tr>

            </thead>
            <tbody v-if="voucher" v-for="(detail,index) in voucher.details">
              <JournalDetailRow  v-if="detail" :ledgers="ledgers" :index="index" :myrow="detail"  ></JournalDetailRow>

            </tbody>
            <tfoot>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td class="font-weight-bold text-right" >&#8377;<span style="padding-right: 0px">{{ indianFormat(creditTotal) }}</span></td>
              <td class="font-weight-bold text-right" >&#8377;<span style="padding-right: 0px">{{ indianFormat(debitTotal) }}</span></td>
              <td></td>
            </tr>
            </tfoot>
          </table>
        </div>
      </div>
      <!-- / Detail Table -->


      <div class="row">
        <div class="col-md-3">
          <div class="form-group form-group-material">
            <label class="control-label text-semibold">Narration</label>
            <textarea id="txtremarks" class="form-control" placeholder="Narration"  v-if="voucher" v-model="voucher.remarks" > </textarea>
          </div>
        </div>


        <div class="col-md-6">
        </div>

        <div class="col-md-3 text-right">
          <button type="button" id="btnclose" class="btn btn-default" data-dismiss="" @click="closeThis">Close</button>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import moment from 'moment-timezone'
  import {userService} from '@/store/auth-header.js'
  import { store } from '@/store/store.js'
  import JournalDetailRow from '@/components/core/JournalDetailRow.vue'

  export default {
    name: 'VoucherPreviewForm',
    store,
    components:{
      JournalDetailRow
    },
    data () {
      return {
        readonly: false,
        ledgers:[],
        creditTotal: 0.0,
        debitTotal: 0.0,
        voucher: JSON.parse('{"id":"","com_id":"","type":0,"status":0,"finyear":0,"vchtype":0,"doc_date":"0001-01-01","doc_no":0,"ref_no":"","ref_date":"0001-01-01","ledger":{"id":"","code":2,"status":0,"type":0,"name":"","alias":"","print_name":"","group":{"id":"","code":0,"status":0,"type":0,"name":"","alias":"","print_name":"","parent_code":0,"nature":0,"affect_profit":false,"behave_like_sub_ledger":false,"net_cr_dr_balance":false,"used_for_calculation":false,"system_object":false},"opening_balance":0,"print_order":0,"gstin":"","address":{"name":"","street":"","city":"","pincode":"","state_code":0,"email":"","telephone":"","mobile":""},"system_object":false},"amount":0,"remarks":"","details":[]}')
      }
    },
    props: {
      myvoucher: {
        type: Object,
        default: () => JSON.parse('{"id":"","com_id":"","type":0,"status":0,"finyear":0,"vchtype":0,"doc_date":"0001-01-01","doc_no":0,"ref_no":"","ref_date":"0001-01-01","ledger":{"id":"","code":0,"status":0,"type":0,"name":"","alias":"","print_name":"","group":{"id":"","code":0,"status":0,"type":0,"name":"","alias":"","print_name":"","parent_code":0,"nature":0,"affect_profit":false,"behave_like_sub_ledger":false,"net_cr_dr_balance":false,"used_for_calculation":false,"system_object":false},"opening_balance":0,"print_order":0,"gstin":"","address":{"name":"","street":"","city":"","pincode":"","state_code":0,"email":"","telephone":"","mobile":""},"system_object":false},"amount":0,"remarks":"","details":[]}')
      }
    },
    beforeMount () {
      this.voucher = this.myvoucher;
      this.voucher.details.forEach(function (lst) {
        lst.credit = lst.credit.toFixed(2);
        lst.debit = lst.debit.toFixed(2);
      });
      this.sumRowAmountTotal();
    },
    created () {
      this.$data.voucher.doc_date = moment().format('YYYY-MM-DD');
      this.$data.voucher.ref_date = moment().format('YYYY-MM-DD');
    },
    mounted () {
      let self = this;
      self.loadData();

      if( self.$data.voucher.doc_date == '0001-01-01' ){
        self.$data.voucher.doc_date = moment().format('YYYY-MM-DD');
      }
    },
    methods:{
      indianFormat(val){
        return parseFloat(val).toFixed(2);
      },
      closeThis(){
        this.$emit('preview_closed');
      },
      sumRowAmountTotal() {
        let self = this;

        self.$data.debitTotal = 0.0;
        self.$data.creditTotal = 0.0;
        self.$data.voucher.details.forEach(function (detail) {
          self.$data.creditTotal += parseFloat(detail.credit);
          self.$data.debitTotal += parseFloat(detail.debit);
        });
      },
      clear(){
        let self = this;
        self.$data.voucher = JSON.parse('{"id":"","com_id":"","type":0,"status":0,"finyear":0,"vchtype":0,"doc_date":"0001-01-01","doc_no":0,"ref_no":"","ref_date":"0001-01-01","ledger":{"id":"","code":2,"status":0,"type":0,"name":"","alias":"","print_name":"","group":{"id":"","code":0,"status":0,"type":0,"name":"","alias":"","print_name":"","parent_code":0,"nature":0,"affect_profit":false,"behave_like_sub_ledger":false,"net_cr_dr_balance":false,"used_for_calculation":false,"system_object":false},"opening_balance":0,"print_order":0,"gstin":"","address":{"name":"","street":"","city":"","pincode":"","state_code":0,"email":"","telephone":"","mobile":""},"system_object":false},"amount":0,"remarks":"","details":[]}');
        self.$data.voucher.voucherDate = moment().format('YYYY-MM-DD');
        self.$data.voucher.referenceDate = moment().format('YYYY-MM-DD');
        self.$data.voucher.details = [];
        self.$data.debitTotal =0.0;
        self.$data.creditTotal = 0.00;
        self.$data.voucher.doc_date = moment().format('YYYY-MM-DD');
        self.$data.voucher.ref_date = moment().format('YYYY-MM-DD');

      },
      loadData() {
        let self = this;

        const requestOptions = {
          mode:'cors',
          headers: userService.authHeader()
        };


        //fetch the Ledgers
        self.$data.ledgers = [];
        fetch(`${process.env.VUE_APP_ROOT_API}v1/accounts/ledger/`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.accepted){
            if(_.isArray(resp.data)){
              self.$data.ledgers = resp.data;
            }
          }else{
            swal (  {title:"Oops" ,  text: resp.message,  type: "error"});
          }
        }).catch(function (err) {
          swal (  {title:"Oops" ,  text: err.toString(),  type: "error"});
        });

      },
    }
  }
</script>

<style scoped>


</style>
