<template>
  <div  id="mycard" class="card">
    <div id="toolbar" class="fixed-table-toolbar" style="padding-right: 20px;padding-left: 20px;padding-top: 10px;">
      <div class="form-group row">
        <label class="col-md-1 col-form-label font-weight-semibold">Ledger:</label>
        <div class="col">
          <select  id="cmbldgr" class="form-control" v-model="ldgr" v-bind:disabled="disableLedger">
            <option value="0">-</option>
            <option v-for="ledger in ledgers" v-bind:value="ledger.code">
              {{ ledger.name }}
            </option>
          </select>
        </div>

        <label class="col-md-1 col-form-label">From:</label>
        <div class="col-md-2">
          <input type="date"  class="form-control" placeholder="From Date"  v-model="from_date" >
        </div>

        <label class="col-md-1 col-form-label">Upto:</label>
        <div class="col-md-2">
          <input type="date"  class="form-control" placeholder="Upto Date" :min="from_date" v-model="upto_date" >
        </div>
        <button type="button" class="btn btn-outline alpha-success text-success-800 border-success-600 legitRipple" @click="loadData">Show</button>
      </div>

    </div>

    <div class="table-responsive">
      <table  id="ledger-table" class="table table-xs table-columned table-hover" data-target=".context-table">
        <thead>
        <tr>
          <th class="d-none">id</th>
          <th style="width: 50px;">S.No</th>
          <th style="width: 110px;">Date</th>
          <th>Description</th>
          <th style="width: 100px;">Vch Type</th>
          <th style="width: 100px;">Vch No</th>
          <th style="width: 150px;text-align: right">Debit</th>
          <th style="width: 150px;text-align: right">Credit</th>
        </tr>
        </thead>
        <tbody v-if="trans"  >
          <!--<tr scope="row" v-if="trans" v-for="(trn,index) in trans">-->
            <!--<td>{{index + 1}}</td>-->
            <!--<td> {{trn.doc_no}} </td>-->
            <!--<td> {{trn.doc_date | formatDate}} </td>-->
            <!--<td> {{ldgr,trn.details | formatDetail}} </td>-->
            <!--&lt;!&ndash;<td> {{trn.details[0].ledger.name}} </td>&ndash;&gt;-->
            <!--<td style="width: 150px;text-align: right"> {{trn.details[0].cr | indianFormat}} </td>-->
            <!--<td style="width: 150px;text-align: right"> {{trn.details[0].dr | indianFormat}} </td>-->
          <!--</tr>-->
        </tbody>
        <tfoot style="background-color: lightgrey;" class="font-weight-semibold">

        </tfoot>
      </table>
    </div>


    <!-- Context Menu -->
    <div id="context-menu" class="context-table">
      <div class="dropdown-menu">
        <a href="#" class="dropdown-item" @click="loadData"><i class="icon-reset" style="color: darkgreen"></i>Refresh</a>
        <!--<div class="dropdown-divider"></div>-->
        <a href="#" class="dropdown-item" >
          <i class="icon-file-eye"></i>Preview</a>

      </div>
    </div>
    <!-- End of Context Menu -->

    <vmodal name="filter-window" transition="nice-modal-fade" :delay="100" :resizable="true" width="42%" height="225" >
      <form>
        <div class="card">
          <div class="card-header header-elements-inline" style="background-color: darkgrey;color: white;padding: 10px;">
            <h3 class="card-title">Date Range</h3>
            <div class="header-elements">
              <div class="list-icons">
                <!--<a class="list-icons-item" data-action="remove" @click="closeRequest"></a>-->
              </div>
            </div>
          </div>

          <div class="card-body">

            <p/>

            <div class="form-group row">
              <label class="col-md-3 col-form-label">From:</label>
              <div class="col-md-9">
                <input type="date" class="form-control"  v-model="from_date" />
              </div>
            </div>

            <div class="form-group row">
              <label class="col-md-3 col-form-label">To:</label>
              <div class="col-md-9">
                <input type="date" class="form-control" v-model="upto_date" />
              </div>
            </div>


            <div class="text-right">
              <button type="button" class="btn btn-outline alpha-success text-success-800 border-success-600 legitRipple" @click="loadData" >Filter<i class="icon-database-refresh ml-2"></i></button>
            </div>

          </div>
        </div>
      </form>
    </vmodal>

    <vmodal name="preview-window" transition="nice-modal-fade" :delay="100" :resizable="true" width="75%" :height="475" @before-open="beforeOpen" @before-close="beforeClose" >
      <PreviewForm v-bind:myvoucher="voucher" v-on:preview_closed="closePreview" ></PreviewForm>
    </vmodal>

<!--    <vmodal name="ibr-move-window" transition="nice-modal-fade" :delay="100" :resizable="true" :width="250" height="300" >-->
<!--      &lt;!&ndash; Form &ndash;&gt;-->
<!--      <form class="modal-body form-validate" action="#">-->
<!--        <div class="text-center mb-3">-->
<!--          <i class="icon-reading icon-2x text-slate-300 border-slate-300 border-3 rounded-round p-3 mb-3 mt-1"></i>-->
<!--          <h5 class="mb-0">Choose Branch to be Move</h5>-->
<!--        </div>-->

<!--        <div class="form-group form-group-feedback form-group-feedback-left">-->
<!--          <select class="form-control" v-model="selected_ibr">-->
<!--            <option v-for="brn in branches" v-bind:value="brn.code">-->
<!--              {{ brn.name }}-->
<!--            </option>-->
<!--          </select>-->
<!--          <div class="form-control-feedback">-->
<!--            <i class="icon-box text-muted"></i>-->
<!--          </div>-->
<!--        </div>-->


<!--        <div class="form-group">-->
<!--          <button type="button" class="btn btn-outline alpha-success text-success-800 border-success-600 legitRipple btn-block" @click="moveToIBR">Move <i class="icon-move-right ml-2"></i></button>-->
<!--        </div>-->

<!--      </form>-->
<!--      &lt;!&ndash; /form &ndash;&gt;-->

<!--    </vmodal>-->

  </div>
</template>

<script>
  import { userService } from '@/store/auth-header.js'
  import { store } from '@/store/store.js'
  import PreviewForm from '@/views/account/voucher/VoucherPreviewForm';

  export default {
    name: 'LedgerDisplayView',
    components:{
      PreviewForm
    },
    store,
    data () {
      return {
        current_ibr : localStorage.getItem('branch_code'),
        mytable: {},
        ledgers: [],
        trans: [],
        disableLedger:false,
        ldgr: 0,
        from_date: moment().format('YYYY-MM-DD'),
        upto_date: moment().format('YYYY-MM-DD'),
        voucher: JSON.parse('{"id":"","com_id":"","type":0,"status":0,"finyear":0,"vchtype":0,"doc_date":"0001-01-01","doc_no":0,"ref_no":"","ref_date":"0001-01-01","ledger":{"id":"","code":0,"status":0,"type":0,"name":"","alias":"","print_name":"","group":{"id":"","code":0,"status":0,"type":0,"name":"","alias":"","print_name":"","parent_code":0,"nature":0,"affect_profit":false,"behave_like_sub_ledger":false,"net_cr_dr_balance":false,"used_for_calculation":false,"system_object":false},"opening_balance":0,"print_order":0,"gstin":"","address":{"name":"","street":"","city":"","pincode":"","state_code":0,"email":"","telephone":"","mobile":""},"system_object":false},"amount":0,"remarks":"","details":[]}')
      }
    },
    created () {
    },
    filters:{
      formatDate(val){
        return val.slice(0, 10).split('-').reverse().join('-');
      },
      indianFormat (value) {
        if(value !== 0)
          return value.toFixed(2);
        else
          return '';
      },
      formatDetail(ldgr, val){
        var result = '<table>';
        val.forEach(function (v) {
          if(v.ledger.code != ldgr){
            result +=  '<tr><td>'+ v.ledger.name +'</td></tr>';
          }
        });
        result += '<table>';
        return result;
      }

    },
    mounted () {
      let self = this;
      self.$data.ldgr = 0;
      self.$data.mytable = $('#ledger-table').children('tbody');

      self.loadLedgers();


      $('.table').contextmenu({
        target: '#context-menu',
        scopes: 'tbody > tr',
        onItem: function (row, e) {
          var id = $(row.children('*')[0]).text()

          if ($(e.target).text() === 'Preview') {
            self.previewVoucher(id);
          }
        }
      });

    },
    methods: {
      beforeOpen(){

      },
      beforeClose(){

      },
      closePreview() {
        this.$modal.hide('preview-window');
      },
      showFilter(){
        this.$modal.show('filter-window');
      },
      blockCard(){
        $('#mycard').block({
          message: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });
      },
      unblockCard(){
        $('#mycard').unblock();
      },
      loadLedgers () {
        let self = this

        const requestOptions = {
          mode: 'cors',
          headers: userService.authHeader()
        }


        //fetch the Ledgers
        self.$data.ledgers = []
        fetch(`${process.env.VUE_APP_ROOT_API}v1/accounts/ledger/`, requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.accepted) {
            if (_.isArray(resp.data)) {
              self.$data.ledgers = resp.data
            }
          } else {
            swal({ title: 'Oops', text: resp.message, type: 'error' })
          }
        }).catch(function (err) {
          swal({ title: 'Oops', text: err.toString(), type: 'error' })
        });
      },
      loadData () {
        let self = this
        const requestOptions = {
          method: 'GET',
          mode: 'cors',
          headers: userService.authHeader()
        }

        self.$modal.hide('filter-window');

        self.$data.disableLedger = true;
        self.blockCard();

        $("#ledger-table > tbody tr").remove();
        $("#ledger-table > tfoot tr").remove();
        self.$data.trans = [];

        if (self.$data.ldgr > 0) {



          fetch(`${process.env.VUE_APP_ROOT_API}v1/accounts/ledgerbook/${store.state.user.finyear}/${self.$data.ldgr}/?ibr=${self.$data.current_ibr}&from=${self.$data.from_date}&upto=${self.$data.upto_date}`, requestOptions).then(userService.handleResponse).then(function (resp) {
            if (resp.accepted) {
              if (_.isArray(resp.data)) {


                self.$data.trans = resp.data;

                let main = {};
                let detail = {};
                let desc = '';
                let curr = {};
                let cr = 0.0;
                let dr = 0.0;
                let particulars = new Map();


                resp.data.forEach(function (trn,idx) {

                  main = {};
                  detail = {};
                  desc = '';
                  curr = {};
                  cr = 0.0;
                  dr = 0.0;
                  particulars.clear();

                  trn.details.forEach(function (d) {

                    if(d.idx == 0){
                      main = d;
                    }

                    if(d.ledger.code !== self.$data.ldgr ){
                      detail = d;
                      desc += d.ledger.name + ", "
                      particulars.set(d.ledger.name,d.ledger.name);
                    }else{
                      curr  = d;

                      //Sum the Same ledger repeats multiple times
                      cr += d.cr;
                      dr += d.dr;
                    }

                  });

                  if(detail.ledger.code == main.ledger.code ){
                    desc = main.ledger.name;
                    particulars.set(main.ledger.name, main.ledger.name);
                  }


                  var row = `
                    <tr>
                        <td class="d-none">${trn._id}</td>
                        <td>${idx + 1}</td>
                        <td>${dateFormatter(trn.doc_date)}</td>
                        <td>${Array.from(particulars.keys()).toString()}</td>
                        <td>${accountingVoucherTypeFormatter(trn.vchtype)}</td>
                        <td>${trn.doc_no}</td>
                        <td style="width: 150px;text-align: right">${indianFormat(dr)}</td>
                        <td style="width: 150px;text-align: right">${indianFormat(cr)}</td>

                    </tr>
                  `;
                  self.$data.mytable.append(row);

                });
                self.loadOpeningBalance();
              }
            } else {
              swal({ title: 'Oops!', text: resp.message, type: 'error' })
            }
          }).catch(function (err) {
            swal({ title: 'Oh noes!', text: err.toString(), type: 'error' })
          }).finally(function () {
            self.$data.disableLedger = false;
            self.unblockCard();
            $('#cmbldgr').focus();
          })
        } else {
          self.$data.disableLedger = false;
          $('#cmbldgr').focus();
          self.unblockCard();
        }

      },

      loadOpeningBalance(){
        let self = this
        const requestOptions = {
          method: 'GET',
          mode: 'cors',
          headers: userService.authHeader()
        }

        $("#ledger-table > tfoot tr").remove();


        fetch(`${process.env.VUE_APP_ROOT_API}v1/accounts/ledgerbook/balance/${self.$data.ldgr}/?ibr=${self.$data.current_ibr}&from=${self.$data.from_date}&upto=${self.$data.upto_date}`, requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.accepted) {

              var footer = $('#ledger-table').children('tfoot');


              if (resp.data.open_bal > 0){
                var row = `
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>Opening</td>
                    <td style="width: 150px;text-align: right"></td>
                    <td style="width: 150px;text-align: right">${indianFormat(resp.data.open_bal)}</td>

                </tr>`;
                footer.append(row);
              }else if (resp.data.open_bal < 0){
                var row = `
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>Opening</td>
                    <td style="width: 150px;text-align: right">${indianFormat( Math.abs(resp.data.open_bal))}</td>
                    <td style="width: 150px;text-align: right"></td>
                </tr>`;
                footer.append(row);
              }


              if (resp.data.credit != 0 || resp.data.debit != 0 ){
                var row1 = `
                  <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>Total</td>
                      <td style="width: 150px;text-align: right">${indianFormat( Math.abs(resp.data.debit))}</td>
                      <td style="width: 150px;text-align: right">${indianFormat( Math.abs(resp.data.credit))}</td>
                  </tr>`;
                footer.append(row1);
              }


              let closing = resp.data.open_bal + resp.data.credit -  resp.data.debit  ;

              if (  closing > 0 ){
                var row1 = `
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>Closing</td>
                        <td style="width: 150px;text-align: right"></td>
                        <td style="width: 150px;text-align: right">${indianFormat( Math.abs(closing))}</td>
                    </tr>`;
                footer.append(row1);
              }else{
                var row1 = `
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>Closing</td>
                        <td style="width: 150px;text-align: right">${indianFormat( Math.abs(closing))}</td>
                        <td style="width: 150px;text-align: right"></td>
                    </tr>`;
                footer.append(row1);
              }


          } else {
            swal({ title: 'Oops!', text: resp.message, type: 'error' })
          }
        }).catch(function (err) {
          swal({ title: 'Oh noes!', text: err.toString(), type: 'error' })
        })
      },
      previewVoucher(id){
        let self = this;
        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };


        self.$data.voucher = {};
        fetch(`${process.env.VUE_APP_ROOT_API}v1/accounts/simplevoucher/${id}/`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.accepted) {

            self.$data.voucher = resp.data;
            self.$data.voucher.doc_date = resp.data.doc_date.slice(0,10);

            self.$modal.show('preview-window');
          } else {
            swal ( { title: "Oops!" ,  text: resp.message, type:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
        });


      },

    }
  }

</script>

<style scoped>

</style>

